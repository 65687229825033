import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import classes from "./PdfViewerComponent.module.scss"

const PdfViewerComponent = ({ pdfContent }) => {
    try {

        return (
            <div className={classes.container}>
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
                    <Viewer fileUrl={pdfContent} httpHeaders={{
                        'Accept': 'application/pdf',
                        "Content-Type": "application/pdf"
                    }}
                        defaultScale={SpecialZoomLevel.ActualSize}
                    />
                </Worker>
            </div>
        );
    } catch (error) {
        console.error('Error:', error);
        return <div>Error al cargar el PDF</div>;
    }
};


export default PdfViewerComponent;