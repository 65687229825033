import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, LinearProgress } from "@mui/material";
import { useDropzone } from 'react-dropzone';
import classes from "./index.module.scss";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, rowSelected, snackbarData, userCredentials } from "recoilState/GlobalState";
import axios from "axios";
import { IconUpload } from "assets/icons";
import { Button, CircularIndeterminate } from "components";
import { SBMDocumentoIPFSCheck } from "services/services-mia/SBMDocumentIPFSCheck";
export default function FileUpload({ updateList }) {
    //Local states
    const [file, setFile] = useState(null);
    const [errorFile, setErrorFile] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadIPFS, setisUploadIPFS] = useState(false);
    const [fileParameters, setFileParameters] = useState({})

    //Global states
    const xgridRowSelected = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const setSnackBarInfo = useSetRecoilState(snackbarData);
    const setModal = useSetRecoilState(modalData)
    const setXgridRowSelected = useSetRecoilState(rowSelected)

    // console.log(xgridRowSelected.row.id)
    const onDrop = async (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles[0].path === ".pdf") {
            return setErrorFile('El archivo debe tener un nombre')
        }
        if (rejectedFiles.length > 0) {
            console.log(rejectedFiles)
            if (rejectedFiles[0].errors[0].code === "file-too-large") {
                return setErrorFile('Solo se permiten archivos menores a 100 MB')
            }
            else {
                return setErrorFile('Solo se permiten archivos PDF.');
            }
        }
        if (acceptedFiles.length > 0) {
            const selectedFile = acceptedFiles[0];

            const data = await getSessionUri(selectedFile.path, selectedFile.size);
            console.log("Response getSessionUri", data);
            if (data && data.uri && data.chunks && data.chunkSize) {
                const { uri, chunks, chunkSize } = data;
                setFile(selectedFile);
                setFileParameters({
                    selectedFile,
                    uri,
                    chunks,
                    chunkSize
                })
                // await uploadFileInChunks(selectedFile, uri, chunks);
            }
        }
    };


    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/pdf": [".pdf"]
        },
        maxSize: 100 * 1024 * 1024,
        onDrop
    });
    let files = acceptedFiles.map(file => (
        <div key={file.path} className={classes.fileCharged}>
            <h1>{file.path}</h1>
            <p>{bytesToMegabytes(file.size)} MB  </p>
        </div>
    ));
    function bytesToMegabytes(bytes) {
        const megabytes = bytes / (1024 * 1024);
        return megabytes < 1 ? megabytes.toFixed(1) : Math.floor(megabytes);
    }
    // console.log(credentials, "credentials")
    const uploadFileInChunks = async (file, url, chunks, chunkSize, carga) => {
        // const chunkSize = Math.ceil(file.size / chunks);
        let start = 0;
        let end = chunkSize;
        let response = null;
        let IPFSResponse = null
        setIsLoading(1);
        for (let i = 0; i <= chunks; i++) {
            const chunk = file.slice(start, end);
            const headers = {
                'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
            };
            try {
                response = await axios.put(url, chunk, {
                    headers, maxRedirects: 0, validateStatus: (status) => {
                        // Aceptar el código de redirección permanente (308)
                        return status === 308 || (status >= 200 && status < 300);
                    },
                });
                console.log(`Chunk ${i + 1} cargado correctamente.`);
                setIsLoading(((i + 1) * 100) / chunks)
                console.log("Headers", response.headers);
                // El servidor debe responder con un encabezado 'Range' que contenga la información
                // para el siguiente fragmento.

                if (response.status === 308) {
                    console.log("status 308")
                    const nextRange = response.headers['range'];
                    const [, nextStart, nextEnd] = nextRange.match(/(\d+)-(\d+)/);
                    start = parseInt(nextEnd) + 1;
                    end = Math.min(start + chunkSize, file.size);
                    console.log("Start ", start, "End", end);
                    console.log("Esto queda en nextStart", nextStart, "nextEnd", nextEnd)
                } else if (response.status === 200) {
                    // Si no se proporciona un encabezado Range en la respuesta, maneja el error o
                    // el comportamiento de reintentar según sea necesario.
                    console.log("status 200")
                    setIsLoading(false);
                    setisUploadIPFS(true)
                    IPFSResponse = await SBMDocumentoIPFSCheck(xgridRowSelected.row.id, credentials)
                    console.log(IPFSResponse, "IPFS")
                    if (IPFSResponse.result[0].uploaded === "Y") {
                        setSnackBarInfo({
                            message: "Archivo cargado correctamente",
                            severity: "success",
                            open: true,
                        });
                        setisUploadIPFS(false)
                        setModal({ isOpen: false })
                        updateList()
                        break;
                    } else if (IPFSResponse.result[0].uploaded === "N") {
                        setSnackBarInfo({
                            message: "No se pudo encriptar el archivo",
                            severity: "error",
                            open: true,
                        });
                        setisUploadIPFS(false)
                        setModal({ isOpen: false })
                        updateList()
                        break;
                    }
                }
            } catch (error) {
                setSnackBarInfo({
                    message: "Ocurrio un error al cargar el archivo",
                    severity: "error",
                    open: true,
                });

                console.error(`Error al cargar el chunk ${i + 1}:`, error);
            }
        }
    };


    const getSessionUri = async (filename, size) => {
        const body = {
            service: "SBMURIGet",
            params: {
                documentId: xgridRowSelected.row.id,
                merchantId: credentials.merchantId,
                filename: filename,
                fileSize: size
            }
        }

        const response = await RequestServiceGet(body, credentials);
        return response[0];
    }
    const sendFile = async () => {
        console.log(fileParameters)
        await uploadFileInChunks(fileParameters.selectedFile, fileParameters.uri, fileParameters.chunks, fileParameters.chunkSize);
    }
    const deleteFile = () => {
        setFile(null)
        setFileParameters({})
        setModal({ isOpen: false })
    }
    useEffect(() => {
        return () => {
            setXgridRowSelected({ status: false, row: {} })
        }
    }, [])

    return (
        <Grid container>
            {isLoading && <LinearProgress variant="determinate" value={isLoading} sx={{ width: "100%" }} />}
            {isUploadIPFS && <div className={classes.containerLoad}><CircularProgress /><p className={classes.textLoad}>Encriptando y subiendo a IPFS...</p></div>}
            <Grid item md={12} lg={12}>
                <Grid container spacing={2} display={"flex"} flexDirection={"column"}>
                    <Grid item lg={12}>
                        <div {...getRootProps({ className: 'dropzone' })} className={classes.dropZone}>
                            <input {...getInputProps()} />
                            <img src={IconUpload} alt="icon-upload" />
                            <p>Arrastra el archivo o <span style={{ textDecoration: "underline", cursor: "pointer" }}> buscalo </span></p>
                        </div>
                        <div className={classes.containerBtn}>
                            <Button onClick={() => deleteFile()} text="Cancelar" />
                            <Button onClick={() => sendFile()} text="Subir" disabled={files.length < 1} />
                        </div>
                    </Grid>
                    <Grid item lg={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        {files.length ? files : <span> 0 archivos subidos</span>}
                        {errorFile ? <span className={classes.error}>{errorFile}</span> : null}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}