export const INSIDE_DRAWER = {
    admin: {
        service_name: "Administración",
        show_drawer: true,
        isOpen: false,
        route: "administracion?servicio=",
    },
    config: {
        service_name: "Configuración",
        show_drawer: true,
        isOpen: false,
        route: "configuracion?servicio=",
    },
    users: {
        service_name: "Usuarios",
        show_drawer: true,
        isOpen: false,
        route: "usuarios",
    },
};

export const Routes = {
    login: {
        route: "/login",
    },
    dashboard: {
        route: "/",
        wordKey: "Home",
        showMenuItem: true,
        needSuperAuth: false,
    },
    zonas: {
        route: "/zonas",
        wordKey: "Zonas",
        showMenuItem: true,
        needSuperAuth: false,
    },
    accesos: {
        route: "/accesos",
        wordKey: "Accesos",
        showMenuItem: true,
        needSuperAuth: false,
    },
    kioscos: {
        route: "/kioscos",
        wordKey: "Kioscos",
        showMenuItem: true,
        needSuperAuth: false,
    },
    kioscosDetail: {
        route: "/kioscos_detalle",
        wordKey: "Detalle de kioscos",
        showMenuItem: false,
        needSuperAuth: false,
    },
    horarios: {
        route: "/horarios",
        wordKey: "Horarios",
        showMenuItem: true,
        needSuperAuth: false,
    },
    usuarios: {
        route: "/usuario",
        wordKey: "Usuarios",
        showMenuItem: true,
        needSuperAuth: false,
    },
    eventos: {
        route: "/eventos",
        wordKey: "Eventos",
        showMenuItem: false,
        needSuperAuth: false,
    },
    administracion: {
        route: "/administracion",
        wordKey: "Administracion de Usuarios",
        showMenuItem: false,
        needSuperAuth: false,
    },
    dashboardDatosPersonales: {
        route: "/administracion/dashboard-usuarios",
        wordKey: "Datos Personales",
        showMenuItem: false,
        needSuperAuth: false,
    },
    documentTable: {
        route: "/servicio/SIGN/admin/documents",
        wordKey: "Documentos",
        showMenuItem: false,
        needSuperAuth: false,
    },
    documentTypeTable: {
        route: "/servicio/SIGN/config/tipo_documento",
        wordKey: "Tipos De Documentos",
        showMenuItem: false,
        needSuperAuth: false,
    },
    sponsorship: {
        route: "/user-sponsorship",
        wordKey: "Sponsorship",
        showMenuItem: false,
        needSuperAuth: false,
    },
    usuariosXServicio: {
        route: "/usuarios-x-servicio",
        wordKey: "Usuarios x servicio",
        showMenuItem: false,
        needSuperAuth: false,
    },
    tipo_documento: {
        route: "/tipo_documento",
        wordKey: "Tipo de Documento",
        showMenuItem: true,
        needSuperAuth: false,
    },
    document: {
        route: "/documents",
        wordKey: "Documentos",
        showMenuItem: true,
        needSuperAuth: false,
    },
};

export const servicesRoutes = {
    IDBASE: [
        {
            route: "/usuarios",
            wordKey: "Usuarios",
            isGroup: false,
        }
    ],
    HGATE: [
        {
            route: "/config",
            wordKey: "Configuración",
            isGroup: true,
            routes: [
                {
                    route: "/zonas",
                    wordKey: "Zonas",
                    isGroup: false
                },
                {
                    route: "/accesos",
                    wordKey: "Accesos",
                    isGroup: false
                },
                {
                    route: "/kioscos",
                    wordKey: "Kioscos",
                    isGroup: false
                },
                {
                    route: "/horarios",
                    wordKey: "Horarios",
                    isGroup: false
                }
            ]
        },
        {
            route: "/admin",
            wordKey: "Administración",
            isGroup: true,
            routes: [
                {
                    route: "/eventos",
                    wordKey: "Eventos",
                    isGroup: false
                },
                {
                    route: "/usuarios",
                    wordKey: "Administracion de Usuarios",
                    isGroup: false
                }
            ],
        },
        {
            route: "/usuarios",
            wordKey: "Usuarios",
            isGroup: false,
        }
    ],
    SIGN: [
        {
            route: "/config",
            wordKey: "Configuración",
            isGroup: true,
            routes: [
                {
                    route: "/tipo_documento",
                    wordKey: "Tipo de Documento",
                    isGroup: false
                }
            ],
        }, {
            route: "/admin",
            wordKey: "Administración",
            isGroup: true,
            routes: [
                {
                    route: "/documents",
                    wordKey: "Documentos",
                    isGroup: false
                }
            ],
        },
        {
            route: "/usuarios",
            wordKey: "Usuarios",
            isGroup: false,
        }
    ]
}
