import React, { useEffect, useState } from "react";

import { documentType as titles } from "consts/titulos-de-tablas";

import { Buttons, HeaderButton, TransitionModal } from "components";
import { Box, Typography } from "@mui/material";

import { DeleteModal, InsertModal, UpdateModal } from "./Actions";


import {
    userCredentials,
    rowSelected,
    routeInfo,
    modalData,
    snackbarData,
} from "recoilState/GlobalState";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Routes } from "consts/Routes-App";
import { ACTIONS } from "consts/Actions";

import XGridDemo from "components/UI/Grid/XGrid";

import { RequestServiceGet } from "services/services-mia/RequestServiceGet";

import tableStyles from "styles/pages/Tables.module.scss";
import styles from "./DocumentType.module.scss";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import EditOutlined from "@mui/icons-material/EditOutlined";
import Delete from "@mui/icons-material/Delete";

export default function DocumentType() {
    const credentials = useRecoilValue(userCredentials);
    const { action } = useRecoilValue(modalData);

    const setRoute = useSetRecoilState(routeInfo);
    const setSnackbarInfo = useSetRecoilState(snackbarData);

    const [row, setRow] = useRecoilState(rowSelected);

    const [isLoading, setIsLoading] = useState(false);
    const [documentTypeLocalState, setDocumentTypeLocalState] = useState([]);

    const handleUpdateTable = () => {
        formatDocumentType();
        setRow({ status: false, row: {} });
    };

    const formatDocumentType = async (
        tipoDeDocumentoId = null,
        merchantId = credentials.merchantId
    ) => {
        setIsLoading((isLoading) => !isLoading);

        const body = {
            service: "SBMTipoDeDocumentoGet",
            params: {
                tipoDeDocumentoId: tipoDeDocumentoId,
                tipoDeDocumentoMerchantId: merchantId,
            },
        };

        const result = await RequestServiceGet(
            body,
            credentials,
            setSnackbarInfo
        );

        const documentTypePartial = result.reduce((acc, item) => {
            const {
                tipoDeDocumentoDescripcion,
                tipoDeDocumentoId,
                tipoDeDocumentoMerchantId,
                tipoDeDocumentoNombre,
                tipoDeDocumentoUFH,
                tipoDeDocumentoUID,
                tipoDeDocumentoVersion,
            } = item;

            const documentTypeType = {
                id: tipoDeDocumentoId,
                tipoDeDocumentoDescripcion: tipoDeDocumentoDescripcion,
                tipoDeDocumentoMerchantId: tipoDeDocumentoMerchantId,
                tipoDeDocumentoNombre: tipoDeDocumentoNombre,
                tipoDeDocumentoUFH: tipoDeDocumentoUFH,
                tipoDeDocumentoUID: tipoDeDocumentoUID,
                tipoDeDocumentoVersion: tipoDeDocumentoVersion,
                [titles[0]]: tipoDeDocumentoNombre,
                [titles[1]]: tipoDeDocumentoDescripcion,
            };

            return [...acc, documentTypeType];
        }, []);

        setDocumentTypeLocalState(documentTypePartial);

        setIsLoading((isLoading) => !isLoading);
    };

    useEffect(() => {
        if (credentials) handleUpdateTable();
    }, [credentials]);

    useEffect(() => {
        setRoute(Routes.documentTypeTable);
    }, [setRoute]);

    return (
        <section className={tableStyles.section}>
            <Box component={"div"} className={styles.helperParagraph}>
                <h2>Elige cómo quieres nombrar a tus tipos de documento</h2>
                <Typography component={"p"} sx={{ fontSize: "28px" }}>
                    Una vez que lo hayas guardado podrás elegirlo como opción a
                    la hora de crear un nuevo documento
                </Typography>
            </Box>

            <TransitionModal
                confirmationModal={
                    (action === ACTIONS.INSERT || action === ACTIONS.UPDATE) ??
                    true
                }
            >
                {action === ACTIONS.UPDATE && (
                    <UpdateModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.DELETE && (
                    <DeleteModal handleUpdateTable={handleUpdateTable} />
                )}
                {action === ACTIONS.INSERT && (
                    <InsertModal handleUpdateTable={handleUpdateTable} />
                )}
            </TransitionModal>
            <Box display={"flex"} justifyContent={"flex-end"}>


                <Buttons filtersApplied={true}>
                    <>
                        <HeaderButton
                            text={"Crear"}
                            disabled={row.status}
                            data={{
                                action: ACTIONS.INSERT,
                                title: "Tipo de Documento - Crear",
                                button: "Enviar",
                            }}
                        >
                            <Add />
                        </HeaderButton>

                        <HeaderButton
                            text={"Eliminar"}
                            disabled={!row.status}
                            data={{
                                action: ACTIONS.DELETE,
                                title: "Tipo de Documento - Eliminar",
                                button: "Confirmar",
                            }}
                        >
                            <Delete />
                        </HeaderButton>

                        <HeaderButton
                            text={"Editar"}
                            disabled={!row.status}
                            data={{
                                action: ACTIONS.UPDATE,
                                title: "Tipo de Documento - Editar",
                                button: "Confirmar",
                            }}
                        >
                            <EditOutlined />
                        </HeaderButton>

                        <HeaderButton
                            text={"Actualizar página"}
                            disabled={row.status}
                            updateList={handleUpdateTable}
                        >
                            <Cached />
                        </HeaderButton>
                    </>
                </Buttons>
            </Box>
            <XGridDemo
                rows={documentTypeLocalState}
                titles={titles}
                loading={isLoading}
            />
        </section>
    );
}
