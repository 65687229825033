import { RequestServiceActions } from "services/services-mia/RequestServiceActions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { secondaryModalData, snackbarData, userCredentials } from "recoilState/GlobalState";
import { CircularProgress } from "@mui/material";
import styles from "./Sign.module.scss"
import { useEffect, useState } from "react";
import { ScoreCard } from "components/ScoreCard/ScoreCard";
import Mapa from "components/utils/mapa/Mapa";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
export default function Sign({ secondaryRowSelected, updateList }) {
    const [load, setLoad] = useState(true)
    const [sign, setSign] = useState(null)
    const [modal, setModal] = useState(false)
    const credentials = useRecoilValue(userCredentials);

    const getTenantName = () => {
        const origin = window.location.origin;
        if (origin === "https://clientes.miaid.me") {
            return "evan-prod"
        } else if (origin === 'https://clientes-dev.miaid.me') {
            return "evan-dev"
        } else if (origin === 'https://clientes-test.miaid.me') {
            return "evan-test"
        } else {
            return "evan-dev"
        }
    }
    const getParticipants = async () => {
        // console.log("console", secondaryRowSelected)
        const body = {
            service: "SBMAutenticacionGet",
            params: {
                tenantNombre: getTenantName(),
                autenticacionId: secondaryRowSelected.row.autenticationId
            }
        }
        const response = await RequestServiceGet(body, credentials);
        setSign(response)
    }

    useEffect(() => {
        getParticipants();
    }, []);
    console.log(sign)
    return (
        <>
            <div className={styles.container}>
                {load ?
                    <>
                        <div className={styles.containerEvan}>
                            <h3>{sign && sign[0]?.usuarioEVANId}</h3>
                        </div>
                        <div className={styles.containerSection}>
                            <div className={styles.containerPrincipal}>
                                <div className={styles.horizontal}>
                                    <img src={sign && sign[0]?.autenticacionImagenURL} className={styles.img} alt="imagen autenticacion" />
                                    {/* <div className={styles.img}></div> */}
                                    <div className={styles.containerPrincipalData}>
                                        <div className={styles.containerData}>
                                            <p className={`${styles.text} ${styles.bold}`}>Autenticación ID:</p>
                                            <p className={`${styles.text} ${styles.big}`}>{sign && sign[0]?.autenticacionId}</p>
                                        </div>
                                        <div className={styles.containerData}>
                                            <p className={`${styles.text} ${styles.bold}`}>Fecha:</p>
                                            <p className={`${styles.text}`}>{`${new Date(sign && sign[0]?.autenticacionFecha).toLocaleDateString("es-ES")}`}</p>
                                        </div>
                                        <div className={styles.containerData}>
                                            <p className={`${styles.text} ${styles.bold}`}>Hora:</p>
                                            <p className={`${styles.text}`}>{`${new Date(sign && sign[0]?.autenticacionFecha).toLocaleTimeString("es-ES", { hour: "2-digit", minute: "2-digit" })} hs`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.containerCards}>
                                    <ScoreCard principal={{ title: "Resultado", result: sign && sign[0]?.autenticacionResultadoOK === "Y" ? "MATCH" : "FAIL" }} second={{ title: "Score", result: sign && sign[0]?.autenticacionScore.toFixed(4) }} />
                                    <ScoreCard /* livenness openModal={() => setModal(true)} */ principal={{ title: "Livenness", result: sign && sign[0]?.autenticacionLiveness === "Y" ? "MATCH" : "FAIL" }} second={{ title: "Confidence", result: sign && sign[0]?.autenticacionLivenessConfidence.toFixed(4) }} />
                                    <ScoreCard principal={{ title: "Autenticador", result: "ACTIVO" }} />
                                </div>
                            </div>
                            <div className={styles.containerBottom}>
                                <div className={styles.left}>
                                    <div className={styles.containerTitle}>
                                        <p>Factor de registración</p>
                                    </div>
                                    <div className={styles.containerDataHorizontalSmall}>
                                        <p className={`${styles.text} ${styles.bold}`}>Nombre:</p>
                                        <p className={`${styles.text}`}>{sign && sign[0]?.factorNombre}</p>
                                    </div>
                                    <div className={styles.containerDataHorizontalSmall}>
                                        <p className={`${styles.text} ${styles.bold}`}>Variante:</p>
                                        <p className={`${styles.text}`}>{sign && sign[0]?.factorVarianteNombre}</p>
                                    </div>
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.containerTitle}>
                                        <p>Data autenticador</p>
                                    </div>
                                    <div className={styles.containerDataHorizontal}>
                                        <p className={`${styles.text} ${styles.bold}`}>Registración ID:</p>
                                        <p className={`${styles.text}`}>{sign && sign[0]?.registracionId}</p>
                                    </div>
                                    {/* <div className={styles.containerDataHorizontal}>
                                        <p className={`${styles.text} ${styles.bold}`}>Registración fecha:</p>
                                        <p className={`${styles.text}`}>{sign && sign[0]?.autenticacionOMNIId}</p>
                                    </div> */}
                                </div>
                            </div>
                            <section className={styles.containerMap}>
                                <div className={styles.containerTitle}>
                                    <p>Geolocalización</p>
                                </div>
                                <div className={styles.contLat}>
                                    <div>
                                        <div className={styles.containerDataHorizontalSmall}>
                                            <p className={`${styles.text} ${styles.bold}`}>Latitud:</p>
                                            <p className={`${styles.text}`}>{sign && sign[0]?.autenticacionLatitud}</p>
                                        </div>
                                        <div className={styles.containerDataHorizontalSmall}>
                                            <p className={`${styles.text} ${styles.bold}`}>Longitud:</p>
                                            <p className={`${styles.text}`}>{sign && sign[0]?.autenticacionLongitud}</p>
                                        </div>
                                    </div>
                                    <Mapa width="100%" lat={sign && sign[0]?.autenticacionLatitud} lng={sign && sign[0]?.autenticacionLongitud} />
                                </div>
                            </section>
                        </div>
                    </> : <CircularProgress />}
            </div>
        </>
    );
}