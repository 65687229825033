import axiosPost from "./axiosPost";

export const getDocument = async (SBMDocumentoId, credentials) => {
    console.log(credentials)
    const body = {
        service: "SBMDocumentoPreviewGet",
        params: {
            SBMDocumentoId: SBMDocumentoId,
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
