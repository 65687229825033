import React, { useEffect, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { FormikTextInput, FormikSelectInput, FormikYesOrNoInput } from "components/UI/Inputs/FormikInputs";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import classes from "./index.module.scss";
import { useRecoilValue } from "recoil";
import { userCredentials } from "recoilState/GlobalState";
import { FormButton } from "components";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_ERROR_MESSAGES } from "consts/errorMessages";
import { useFormik } from "formik";
export default function FormActions({ handleSubmit, initialValues }) {

    //Local States
    const [documentType, setDocuemntType] = useState([]);
    const formikRef = useRef();
    //Global States 
    const credentials = useRecoilValue(userCredentials);

    //Hooks 
    useEffect(() => {
        console.log("Esto es la referencia de formik", formikRef)
        getDocumentType();
    }, [])


    //Functions 
    const getDocumentType = async () => {
        try {
            const body = {
                service: "SBMTipoDeDocumentoGet",
                params: {}
            }
            const response = await RequestServiceGet(body, credentials);
            const responseToDocumentType = response.map((document) => {
                const { tipoDeDocumentoId, tipoDeDocumentoNombre } = document;
                return {
                    tipoDeDocumentoId,
                    tipoDeDocumentoNombre,

                }
            })
            formikRef.current.setFieldValue("tipoDocumento", responseToDocumentType[0].tipoDeDocumentoId);
            setDocuemntType(responseToDocumentType);
        } catch (e) {
            //Manejar error; 
        }
    }
    const ayer = () => {
        let hoy = new Date();
        console.log(hoy)
        let DIA_EN_MILISEGUNDOS = 24 * 60 * 60 * 1000;
        let ayer = new Date(hoy.getTime() - DIA_EN_MILISEGUNDOS);
        console.log(ayer)
        return ayer;
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                handleSubmit(values);
            }}
            validationSchema={
                yup.object({
                    nombreDocumento: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    descripcion: yup.string(FORM_ERROR_MESSAGES.text)
                        .required(FORM_ERROR_MESSAGES.required),
                    fechaDocumento: yup.date(FORM_ERROR_MESSAGES.date)
                        .required(FORM_ERROR_MESSAGES.required)
                        .min(ayer(), "Fecha debe ser mayor o igual a hoy"),

                })
            }
            innerRef={formikRef}

        >

            {({
                values
            }) => (
                <Form noValidate>
                    <Grid container height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"} className={classes.container}>
                        <Grid item md={12} lg={12}>
                            <Grid container spacing={2}>

                                <Grid item lg={4}>

                                    <FormikSelectInput labelText={"Tipo de documento"} name={"tipoDocumento"} >
                                        {
                                            documentType.map((document) => (
                                                <option value={document.tipoDeDocumentoId} key={document.tipoDeDocumentoId}>{document.tipoDeDocumentoNombre} </option>
                                            ))
                                        }
                                    </FormikSelectInput>
                                </Grid>
                                <Grid item lg={5}>
                                    <FormikTextInput labelText={"Nombre del documento"} name={"nombreDocumento"} />
                                </Grid>
                                <Grid item lg={3}>
                                    <FormikTextInput labelText={"Fecha de documento"} type={"date"} name={"fechaDocumento"} />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Row 3 */}
                        <Grid item md={12} lg={12}>
                            <FormikTextInput labelText={"Descripcion"} type="text" fullWidth placeholder="Ejemplo de texto: Contrato comercial bilateral" name={"descripcion"} />
                        </Grid>
                        <Grid item md={12} lg={12}>
                            <FormikTextInput labelText={"Referencia"} type="text" fullWidth placeholder="Documento seguido por..." name={"referencia"} />
                        </Grid>
                        <Grid container spacing={2}>

                            <Grid item lg={2} display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
                                <Box display={"flex"} flexDirection={"column"}>
                                    <span className={classes.titleInput}>Req. Firma</span>

                                    <FormikYesOrNoInput name={"requiereFirma"} />
                                </Box>
                            </Grid>
                            {
                                values.requiereFirma && (
                                    <>
                                        <Grid item lg={4} >
                                            <FormikTextInput labelText={"Cant. firmantes internos"} name={"cantFirmantesInternos"} type={"number"} />
                                        </Grid>
                                        <Grid item lg={6}>
                                            <FormikTextInput labelText={"Cant. firmantes externos"} name={"cantFirmantesExternos"} type={"number"} />
                                        </Grid>
                                    </>
                                )
                            }

                        </Grid>
                        <FormButton
                            newFormData={{ button: "Continuar" }}
                            hasConfirmationModal={true} />
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}