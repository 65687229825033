import axiosPost from "./axiosPost";

export const SBMDocumentoIPFSCheck = async (SBMDocumentoId, credentials) => {
    console.log(credentials)
    const body = {
        service: "SBMDocumentoIPFSCheck",
        params: {
            SBMDocumentoId: SBMDocumentoId,
            merchantId: credentials.merchantId
        },
    };

    const result = await axiosPost(body, credentials);
    return result;
};
