import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { FormikSelectInput } from 'components/UI/Inputs/FormikInputs';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { rowSelected, secondaryModalData, snackbarData, userCredentials } from 'recoilState/GlobalState';
import { RequestServiceGet } from 'services/services-mia/RequestServiceGet';
import { RequestServiceActions } from 'services/services-mia/RequestServiceActions';
import classes from "./index.module.scss";
import XGridDemo from 'components/UI/Grid/XGrid';
import { inviteParticipants as titles } from 'consts/titulos-de-tablas';
import { FormButton } from "components";
import FormSignerExt from '../../FormSignerExt';
function InsertModalParticipants({ updateList, rows, participants }) {

    //Local States
    const [roles, setRoles] = useState([]);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [signers, setSigners] = useState([]);
    const [userSigner, setUserSigner] = useState([]);
    const formikRef = useRef();
    //Global States
    const credentials = useRecoilValue(userCredentials);
    const xgridRowSelected = useRecoilValue(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setSecondaryModalData = useSetRecoilState(secondaryModalData);
    const [secondaryXgridRowSelected, setSecondaryXgridRowSelected] = useState();
    //Hooks
    useEffect(() => {
        Promise.all([getRol(), getInternalSigner()]);
    }, []);

    useEffect(() => {
        if (secondaryXgridRowSelected?.row) {
            setUserSigner([...userSigner, secondaryXgridRowSelected?.row])
        }

    }, [secondaryXgridRowSelected])


    console.log("Esto es user signer", userSigner);

    //Functions
    const getRol = async () => {
        const body = {
            service: "SBMRolGet",
            params: {}
        }
        const response = await RequestServiceGet(body, credentials);
        const responseToRol = response.map((rol) => {
            return {
                SBMRolId: rol.SBMRolId,
                SBMRolNombre: rol.SBMRolNombre
            }
        })

        console.log("Formik ref", formikRef);

        setRoles(responseToRol);
        ;
    }

    const defineFirstValueFormik = () => {
        if (rows.firmantesInternos > participants.filter(e => e.rol === "Firmante Representante").length) {
            return { roles: "1" }
        } else if (rows.firmantesExternos > participants.filter(e => e.rol === "Firmante Externo").length) {
            return { roles: "2" }
        } else {
            return { roles: "3" }
        }
    }

    const getInternalSigner = async () => {
        const body = {
            service: "SBMFirmanteInternoGet",
            params: {
                SBMDocumentoId: xgridRowSelected.row.id
            }
        }
        const response = await RequestServiceGet(body, credentials);

        const responseToSigners = response.map((signer) => {
            return {
                id: signer.msuId,
                mail: signer.usuarioMail,
                usuarioId: signer.usuarioId,
                usuarioNroDocumentoX: signer.usuarioNroDeDocumentoX,
                tipoDocumentoId: signer.tipoDeDocumentoId,
                usuarioNroDeDocumento: signer.usuarioNroDeDocumentoX,
                [titles[0]]: `${signer.usuarioNombres} ${signer.usuarioApellidos}`,
                [titles[1]]: signer.usuarioNroDeDocumentoX,
                [titles[2]]: signer.tipoDeDocumentoNombre
            }
        })
        setSigners(responseToSigners);
    }
    // console.log(signers, "signers")
    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("cerrado")
        if (userSigner.length) {
            const promises = userSigner.map(async (signer) => {
                const body = {
                    service: "SBMDocumentoUsuarioIns",
                    params: {
                        SBMDocumentoId: xgridRowSelected.row.id,
                        usuarioId: signer.usuarioId,
                        rolId: Number(formData.roles),
                        tipoDeDocumentoId: signer.tipoDocumentoId,
                        usuarioNroDeDocumentoX: signer.usuarioNroDocumentoX,
                        usuarioMail: signer.mail,
                        usuarioNroDeDocumento: signer.usuarioNroDeDocumento
                    }
                }
                return RequestServiceActions(body, credentials, null, setSnackbarInfo);
            });
            console.log("cierro")
            await Promise.all(promises);
            await updateList()
            setSecondaryModalData({
                isOpen: false
            })
        }
    }

    console.log(participants, rows, roles)
    //Renders 
    const renderFormSelectRol = () => (
        <Formik
            initialValues={defineFirstValueFormik()}
            onSubmit={(value) => {
                console.log("Value", value)
                setFormData(value)
                setStep(2)
            }}
            innerRef={formikRef}
        >
            <Form>
                <FormikSelectInput name={"roles"} labelText={"Rol"} >
                    {
                        roles.map((rol) => (
                            rol.SBMRolNombre === "Firmante Representante" ?
                                rows.firmantesInternos > participants.filter(e => e.rol === "Firmante Representante").length && <option value={rol.SMBRolId} key={rol.SMBRolId}>{rol.SBMRolNombre}</option> :
                                rol.SBMRolNombre === "Firmante Externo" ?
                                    rows.firmantesExternos > participants.filter(e => e.rol === "Firmante Externo").length && <option value={rol.SMBRolId} key={rol.SMBRolId}>{rol.SBMRolNombre}</option> :
                                    <option value={rol.SMBRolId} key={rol.SMBRolId}>{rol.SBMRolNombre}</option>
                        ))
                    }
                </FormikSelectInput>
                <Box display={"flex"} width={"100%"} justifyContent={"center"} mt={10} gap={5}>
                    <FormButton
                        newFormData={{ button: "Continuar" }}
                    />
                </Box>
            </Form>
        </Formik>
    );

    const renderTableWithParticipants = () => (
        <form onSubmit={handleSubmit}>
            <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item>
                    <TextField label={"Rol"} value={roles.find((rol) => rol.SBMRolId === Number(formData.roles)).SBMRolNombre} fullWidth InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item display={"flex"} mt={2}>
                    <Typography variant='h1' style={{ fontSize: "15px" }}>
                        {`Selecciona los firmantes:`}
                    </Typography>
                    <Typography variant='h1' style={{ fontSize: "15px" }} ml={1}> <span className={classes.round}>{xgridRowSelected.row.firmantesInternos}</span> firmantes restantes</Typography>
                </Grid>
                <Grid item height={"40vh"} mt={5}>
                    <XGridDemo
                        titles={titles}
                        rows={signers}
                        checkboxSelection={true}
                        secondarySelected={secondaryXgridRowSelected}
                        setSecondarySelected={setSecondaryXgridRowSelected}
                    />
                </Grid>
                <FormButton
                    newFormData={{ button: "Invitar" }}
                />
            </Grid>
        </form>
    );
    console.log("row", roles.find((e) => e.SBMRolId === Number(formData.roles))?.SBMRolNombre)
    if (step === 1) {
        return (
            renderFormSelectRol()
        )
    } else if (step === 2 && formData.roles === "1") {
        return (
            renderTableWithParticipants()
        )
    } else {
        return (
            <FormSignerExt rolData={roles.find((e) => e.SBMRolId === Number(formData.roles))?.SBMRolNombre || formData.roles} roles={roles} updateList={updateList} />
        )
    }
}

export default InsertModalParticipants;
