import React, { useEffect, useState } from "react";
import XGridDemo from "components/UI/Grid/XGrid";
import { participants as titles } from "consts/titulos-de-tablas";
import classes from "./index.module.scss";
import { Buttons, TransitionModal2, HeaderButton } from "components";
import { RequestServiceGet } from "services/services-mia/RequestServiceGet";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rowSelected, secondaryModalData, userCredentials } from "recoilState/GlobalState";
import Add from "@mui/icons-material/Add";
import Cached from "@mui/icons-material/Cached";
import Delete from "@mui/icons-material/Delete";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { Box, Typography } from "@mui/material";
import { ACTIONS } from "consts/Actions";
import { InsertModalParticipants, SignModalParticipants } from "./Actions";
import { DeleteModalParticipants } from "./Actions"
export default function Participants({ rows }) {
    //Local states 
    const [participants, setParticipants] = useState([]);
    const [loadingRow, setLoadingRow] = useState(false);
    const [secondarySelected, setSecondarySelected] = useState({ status: false, row: {} });

    //Global States
    const xgridRowSelected = useRecoilValue(rowSelected);
    const { action } = useRecoilValue(secondaryModalData);
    const credentials = useRecoilValue(userCredentials);

    const setXgridRowSelected = useSetRecoilState(rowSelected)

    useEffect(() => {
        getParticipants();

    }, []);
    useEffect(() => {
        return () => {
            setXgridRowSelected({ status: false, row: {} })
        }
    }, [])

    const getParticipants = async () => {
        setLoadingRow(true)
        try {
            const body = {
                service: "SBMDocumentoUsuarioGet",
                params: {
                    SBMDocumentoId: xgridRowSelected.row.id
                }
            }
            const states = {
                P: "Pendiente",
                F: "Firmado",
                R: "Rechazado"
            }
            const response = await RequestServiceGet(body, credentials);
            console.log(response, "response")
            const responseToParticipants = response.map((participant) => {
                return {
                    id: participant.SBMDocumentoUsuarioId,
                    rol: participant.SBMRolNombre,
                    version: participant.SBMDocumentoUsuarioVersion,
                    [titles[0]]: `${participant.usuarioNombres === null ? "" : participant.usuarioNombres} ${participant.usuarioApellidos === null ? "" : participant.usuarioApellidos}`,
                    [titles[1]]: participant.tipoDeDocumentoNombre,
                    [titles[2]]: participant.usuarioNroDeDocumentoX,
                    [titles[3]]: participant.SBMRolNombre,
                    [titles[4]]: participant.usuarioMail,
                    [titles[5]]: participant.SBMDocumentoUsuarioFechaEstado,
                    [titles[6]]: states[participant.SBMDocumentoUsuarioEstado],
                    autenticationId: participant.autenticacionId
                }
            });
            setParticipants(responseToParticipants);
        } catch (e) {
            console.log("Hola");
        }
        setLoadingRow(false)
    }

    console.log("Segunda seleccion", secondarySelected);
    console.log(participants)
    return (
        <section className={classes.container}>
            <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="h1" style={{ fontSize: "20px" }}>
                    {`Documento: ${xgridRowSelected.row.nombre}`}
                </Typography>
                <Typography variant="h2" style={{ fontSize: "16px" }}>
                    {`Firmantes Externos: ${rows.firmantesExternos}`}
                </Typography>
                <Typography variant="h2" style={{ fontSize: "16px" }}>
                    {`Firmantes Representantes: ${rows.firmantesInternos}`}
                </Typography>
            </Box>
            {/**
                * Actions buttons
            */}
            <Box display={"flex"} justifyContent={"flex-end"}>


                <Buttons filtersApplied={true}>
                    <HeaderButton
                        disabled={secondarySelected.status === true}
                        text={"Crear"}
                        data={{
                            action: ACTIONS.INSERT,
                            title: "Invitar Participante",
                            button: "Continuar",
                            secondaryModal: true
                        }}
                    >
                        <Add />
                    </HeaderButton>

                    <HeaderButton
                        text={"Actualizar"}
                        handleClick={() => getParticipants()}
                    >
                        <Cached />
                    </HeaderButton>

                    <HeaderButton
                        disabled={secondarySelected.status === false || secondarySelected?.row && secondarySelected?.row["Estado"] !== "Firmado"}
                        text={"Ver firma"}
                        data={{
                            action: "sign",
                            title: "Ver firma",
                            button: "Enviar",
                            secondaryModal: true
                        }}>
                        <VisibilityRoundedIcon />
                    </HeaderButton>

                    <HeaderButton
                        disabled={secondarySelected.status === false || secondarySelected.row.Estado === "Firmado"}
                        text={"Eliminar"}
                        data={{
                            action: ACTIONS.DELETE,
                            title: "Borrar Participante",
                            button: "Enviar",
                            secondaryModal: true
                        }}>
                        <Delete />
                    </HeaderButton>
                </Buttons>
            </Box>
            {

                <TransitionModal2>
                    {
                        action === ACTIONS.INSERT && (
                            <InsertModalParticipants updateList={getParticipants} rows={rows} participants={participants} />
                        )
                    }
                    {
                        action === ACTIONS.DELETE && (
                            <DeleteModalParticipants setRow={setSecondarySelected} secondaryRowSelected={secondarySelected} updateList={getParticipants} />
                        )
                    }
                    {
                        action === "sign" && (
                            <SignModalParticipants secondaryRowSelected={secondarySelected} />
                        )
                    }
                </TransitionModal2>

            }
            <XGridDemo
                titles={titles}
                rows={participants}
                secondarySelected={secondarySelected}
                setSecondarySelected={setSecondarySelected}
                loading={loadingRow}
            />
        </section>


    );
}