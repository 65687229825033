import React, { useEffect, useState } from "react";
import FormActions from "../FormAction/FormActions";
import { RequestServiceActions } from "services/services-mia/RequestServiceActions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { modalData, rowSelected, snackbarData, userCredentials } from "recoilState/GlobalState";
import { ACTIONS } from "consts/Actions";
import { getDocument } from "services/services-mia/getDocument";
import PdfViewerComponent from "./PdfViewerComponent";
import axios from "axios";
import styles from "./ViewDoc.module.scss"

export default function ViewDoc({ updateList }) {
    const credentials = useRecoilValue(userCredentials);
    const xgridRowSelected = useRecoilValue(rowSelected);
    const [pdf, setPdf] = useState({ status: false, content: "" })

    const getDoc = async () => {
        const response = await getDocument(xgridRowSelected.row.id, credentials)
        console.log("response documento", response)
        setPdf({ status: true, content: response.result[0].uri })
    }

    const handleDownloadPdf = async (e) => {
        e.preventDefault()
        const responseDownload = await getDocument(xgridRowSelected.row.id, credentials)
        console.log(responseDownload.result[0])
        const { data } = await axios.get(responseDownload.result[0].uri, {
            headers: {
                "Accept": "application/pdf",
                "Content-Type": "application/pdf"
            },
            responseType: "blob",
            data: null
        })
        console.log(data, "data")
        let urlBlob = window.URL.createObjectURL(new Blob([data]));
        let a = document.createElement("a")
        a.href = urlBlob
        a.download = `${xgridRowSelected.row["Nombre"]}.pdf`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    useEffect(() => {
        getDoc()
    }, [])
    console.log("Row", xgridRowSelected)
    return (
        <>
            <section>
                {pdf.status && <PdfViewerComponent pdfContent={pdf.content} />}
            </section>
            <button className={styles.btn} onClick={handleDownloadPdf}>descargar</button>
        </>
    );
}