import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { FormikSelectInput, FormikTextInput } from "components/UI/Inputs/FormikInputs";
import { RequestServiceGet } from "../../../../../../services/services-mia/RequestServiceGet"
import { Formik, Form } from "formik";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rowSelected, secondaryModalData, snackbarData, userCredentials } from "recoilState/GlobalState";
import { FormButton } from "components";
import { MIAUsuarioCheck } from "services/services-mia/MiaUsuarioCheck";
import { RequestServiceActions } from "services/services-mia/RequestServiceActions";


export default function FormSignerExt({ rolData, roles, updateList }) {
    //Local States 
    const [country, setCountry] = useState([]);
    const [docType, setDocType] = useState([]);
    const [step, setStep] = useState(1);
    const [miaUser, setMiaUser] = useState({});
    const [isUserMia, setIsUserMia] = useState(false)
    const formikRef = useRef();
    //Global States
    const credentials = useRecoilValue(userCredentials);
    const xgridRowSelected = useRecoilValue(rowSelected);
    const setSnackbarInfo = useSetRecoilState(snackbarData);
    const setSecondaryModalData = useSetRecoilState(secondaryModalData);

    const gender = [
        { value: "M", name: "Masculino" },
        { value: "F", name: "Femenino" },
        { value: "X", name: "No binario" }

    ]

    //Hooks
    useEffect(() => {
        getCountry()
    }, [])

    useEffect(() => {
        if (formikRef.current && formikRef.current.values.country) {
            getDocumentType()
        }
    }, [formikRef.current?.values?.country])

    //Functions
    const getCountry = async () => {
        const body = {
            service: "MIAPaisGet",
            params: {
            }
        }
        const response = await RequestServiceGet(body, credentials);
        const responseToCountry = response.map((country) => {
            return {
                id: country.paisId,
                name: country.paisNombre
            }
        })
        setCountry(responseToCountry);
    }

    const getDocumentType = async (value) => {

        const body = {
            service: "TipoDeDocumentoGet",
            params: {
                paisId: !value ? formikRef.current.values.country : value
            }
        }
        const response = await RequestServiceGet(body, credentials);
        const responseToDocumentType = response.map((doc) => {
            return {
                id: doc.tipoDeDocumentoId,
                name: doc.tipoDeDocumentoNombre
            }
        })
        setDocType(responseToDocumentType)
    }
    const handleOnChange = async (event) => {
        if (event.target.name === "country") {
            await getDocumentType(event.target.value)
        }
        if (event.target.name === "mail") {
            setMiaUser({ ...miaUser, usuarioMail: event.target.value })
            console.log(miaUser)
        }
    }

    const handleSubmit = async (values) => {
        console.log("Estos son los values", values);
        let valuesTransform = { ...values, documentNumber: values.documentNumber.replace(/[^0-9]/g, '') }
        console.log(valuesTransform)
        if (step === 1 && !isUserMia) {
            const response = await MIAUsuarioCheck(valuesTransform.documentNumber, valuesTransform.documentType)
            if (response.errmsg === "Usuario inexistente") {
                console.log("if Usuario inexistente")
                setIsUserMia(false)
                setStep(2);
            } else if (response.errmsg === "Usuario existente") {
                console.log("if Usuario existente")
                const body = {
                    service: "SBMUsuarioMinimoGet",
                    params: {
                        usuarioNroDeDocumentoX: valuesTransform.documentNumber,
                        tipoDeDocumentoId: valuesTransform.documentType
                    }
                }
                const response = await RequestServiceGet(body, credentials);

                setMiaUser(response[0]);

                return setIsUserMia(true)
            }
        } else if (step === 1 && isUserMia) {
            console.log("if step === 1 && isUserMia")
            const body = {
                service: "SBMDocumentoUsuarioIns",
                params: {
                    SBMDocumentoId: xgridRowSelected.row.id,
                    usuarioId: miaUser.usuarioId,
                    rolId: roles.find((rol) => rol.SBMRolNombre === rolData)?.SBMRolId,
                    tipoDeDocumentoId: miaUser.tipoDeDocumentoId,
                    usuarioNroDeDocumentoX: miaUser.usuarioNroDeDocumentoX,
                    usuarioMail: miaUser.usuarioMail,
                    usuarioNroDeDocumento: Number(miaUser.usuarioNroDeDocumento),
                    usuarioSexo: valuesTransform.gender
                }
            }

            const response = await RequestServiceActions(body, credentials, null, setSnackbarInfo)
            if (response.status.code === 1) {
                setSecondaryModalData({ isOpen: false })
                await updateList()
            }

        } else if (step === 2 && !isUserMia) {
            console.log("if step === 2 && !isUserMia")
            console.log(valuesTransform)
            const body = {
                service: "SBMDocumentoUsuarioIns",
                params: {
                    usuarioApellidosYNombres: `${valuesTransform.name} ${valuesTransform.surname}`,
                    SBMDocumentoId: xgridRowSelected.row.id,
                    rolId: roles.find((rol) => rol.SBMRolNombre === rolData)?.SBMRolId,
                    tipoDeDocumentoId: valuesTransform.documentType,
                    usuarioNroDeDocumentoX: valuesTransform.documentNumber,
                    usuarioMail: valuesTransform.email,
                    usuarioNroDeDocumento: Number(valuesTransform.documentNumber),
                    usuarioSexo: valuesTransform.gender
                }
            }

            const response = await RequestServiceActions(body, credentials, null, setSnackbarInfo)
            console.log(response, "esta es la response")
            if (response.status.code === 1) {
                console.log("if response.status.code === 1")
                setSecondaryModalData({ isOpen: false })
                await updateList()
            }
        }
    }


    console.log(miaUser, "user")
    // Renders 
    const initialForm = () => (
        <Formik
            initialValues={{
                rol: rolData,
                country: 1,
                documentType: 1,
                gender: "X"
            }}
            innerRef={formikRef}
            onSubmit={handleSubmit}

        >
            <Form onChange={handleOnChange}>
                <Grid container>
                    {isUserMia && miaUser ?
                        (
                            <Typography variant="h1" style={{ fontSize: "2em" }} color={"primary"}>
                                {`${miaUser.usuarioNombres} ${miaUser.usuarioApellidos}`}
                            </Typography>
                        )


                        : null}
                    <Grid item lg={12}>
                        <FormikTextInput defaultValue={rolData} name={"rol"} labelText={"Rol"} disabled={true} />
                    </Grid>

                    <Grid item lg={12} container columnSpacing={3}>
                        <Grid item lg={6}>
                            <FormikSelectInput name={"country"} labelText={"Pais"} disabled={isUserMia}>
                                {
                                    country.map((country) => (
                                        <option value={country.id}>{country.name}</option>
                                    ))
                                }
                            </FormikSelectInput>
                        </Grid>
                        <Grid item lg={6}>
                            <FormikSelectInput name={"gender"} labelText={"Genero"}>
                                {
                                    gender.map((genero) => (
                                        <option value={genero.value}>{genero.name}</option>
                                    ))
                                }
                            </FormikSelectInput>
                        </Grid>
                    </Grid>
                    <Grid item lg={12}>
                        <Grid container display={"flex"} columnSpacing={3}>
                            <Grid item lg={6}>
                                <FormikSelectInput name={"documentType"} labelText={"Tipo de documento"} disabled={isUserMia}>
                                    {
                                        docType.map((doc) => (
                                            <option value={doc.id}>{doc.name}</option>
                                        ))
                                    }
                                </FormikSelectInput>
                            </Grid>
                            <Grid item lg={6}>
                                <FormikTextInput name={"documentNumber"} placeholder={"445454554"} labelText={"Numero de documento"} disabled={isUserMia} />
                            </Grid>

                            {isUserMia && !miaUser.email ?
                                (<Grid item lg={12}>
                                    <FormikTextInput name={"mail"} placeholder={"example@hotmail.com"} labelText={"Email"} disabled={miaUser.email} />
                                </Grid>)
                                : null}
                        </Grid>

                    </Grid>
                </Grid>
                {
                    !isUserMia && step === 2 && (
                        <>
                            <Grid item lg={6}>
                                <FormikTextInput name={"name"} labelText={"Nombre"} />
                            </Grid>
                            <Grid item lg={6}>
                                <FormikTextInput name={"surname"} labelText={"Apellido"} />
                            </Grid>
                            <Grid item lg={6}>
                                <FormikTextInput name={"email"} labelText={"E-mail"} />
                            </Grid>
                        </>

                    )
                }

                <FormButton
                    newFormData={{ button: isUserMia || step === 2 ? "Invitar" : "Continuar" }}
                />
            </Form>
        </Formik>
    );



    return (
        initialForm()
    )


}