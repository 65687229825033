import { RequestServiceGet } from "./services-mia/RequestServiceGet";

export const getUserCredentials = async () => {
    if (
        localStorage.getItem("hooliToken") &&
        localStorage.getItem("sessionId")
    ) {
        return {
            hooliToken: localStorage.getItem("hooliToken"),
            sessionId: localStorage.getItem("sessionId"),
            merchantId: localStorage.getItem("merchantId"),
            merchantRZ: localStorage.getItem("merchantRZ"),
            merchantNombre: localStorage.getItem("merchantNombre"),
            merchantCUIT: localStorage.getItem("merchantCUIT"),
        };
    }

    return null;
    /*
    let userCredentials;
    try {
        userCredentials = await RequestServiceGet({
            service: "TemporalSession",
            params: {},
        })
    } catch (err) {
        console.log(`Error: ${err}`);
    }

    if (!userCredentials || !(userCredentials?.length > 0)) {
        console.log(`Fetch failed`);
    }

    localStorage.setItem("hooliToken", userCredentials[0].hooliToken);
    localStorage.setItem("sessionId", userCredentials[0].sessionId);
    localStorage.setItem("merchantId", 1);
    localStorage.setItem("merchantRZ", "Moov");
    localStorage.setItem("merchantNombre", "Moov");
    localStorage.setItem("merchantCUIT", "30-71512361-2");

    const newCredentials = {
        ...userCredentials[0],
        merchantId: 1,
        merchantRZ: "Moov",
        merchantNombre: "Moov",
        merchantCUIT: "30-71512361-2",
    };

    return newCredentials;
    */
};
